import React from 'react';

const Footer = () => {
  return (
    <div className="section-container">
      <h3 style={{ fontSize: '1.5rem' }}>&#169; 2023 Tatyana Karlen</h3>
    </div>
  );
};

export default Footer;
